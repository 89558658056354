import axios from "axios";
import { CartItem, Coupon, Product } from "../types";
import { toast } from "react-toastify";
import { useJwt } from "react-jwt";
import moment from "moment";
import { nanoid } from "nanoid";
import wooCommerce from "./woocommerce";
import {
	getProductByVariantId,
	getProductDetails,
} from "../services/woocommerceServices/Products";
import { uniqueId } from "lodash";
export const getSearchResults = async (query: string) => {
	const URL = `${process.env.GATSBY_SITE_URL}/wp-json/wp/v2/search/?search=`;
	const searchQuery = URL + query;
	if (query.length > 0 && query) {
		try {
			const res = await axios.get(searchQuery);
			const results = res.data;
			return results;
		} catch (error: any) {
			// console.log(error.message);
		}
	}
};

export const checkCouponValidity = (
	coupon: Coupon | null,
	couponList: (Coupon | undefined)[],
	userId?: string | null,
	userEmail?: string | null | undefined
) => {
	const cartItems: CartItem[] =
		JSON.parse(localStorage.getItem("cartData") as string) || {};
	if (coupon === null) {
		toast.error("Invalid coupon code");
		return false;
	}

	const individual_use_coupon_index = couponList.findIndex(
		(coupon) => coupon?.individual_use === true
	);

	if (individual_use_coupon_index > -1) {
		toast.error("Coupon cannot be used in conjunction with other coupons.");
		return false;
	}

	// Check if the coupon has expired
	const now = new Date();
	if (coupon.date_expires) {
		const expires = new Date(coupon.date_expires);
		if (now > expires) {
			toast.error("Coupon has expired.");
			return false;
		}
	}

	if (cartItems?.length === 0) {
		return false;
	}

	// Check minimum and maximum amount
	const cartTotal = cartItems?.reduce(
		(total, item) => total + item.price * item.quantity,
		0
	);
	if (
		parseFloat(coupon.minimum_amount) &&
		cartTotal < parseFloat(coupon.minimum_amount)
	) {
		toast.error(`Cart total must be at least ${coupon.minimum_amount}`);
		return false;
	}
	if (
		parseFloat(coupon.maximum_amount) > 0 &&
		cartTotal > parseFloat(coupon.maximum_amount)
	) {
		toast.error(`Cart total must not exceed ${coupon.maximum_amount}`);
		return false;
	}

	// Check individual use
	if (coupon.individual_use && couponList?.length > 0) {
		toast.error("Coupon cannot be used in conjunction with other coupons.");
		return false;
	}

	// Check applicable products
	if (coupon.product_ids.length > 0) {
		const productIdsInCart = cartItems.map((item) => item.productId);
		const applicableProducts = coupon.product_ids.some((id) =>
			productIdsInCart.includes(id)
		);
		if (!applicableProducts) {
			toast.error("Coupon is not applicable to any products in the cart");
			return false;
		}
	}

	// Check excluded products
	if (coupon.excluded_product_ids.length > 0) {
		const productIdsInCart = cartItems.map((item) => item.productId);
		const excludedProducts = coupon.excluded_product_ids.some((id) =>
			productIdsInCart.includes(id)
		);
		if (excludedProducts) {
			toast.error("Coupon is not applicable to some products in the cart");
			return false;
		}
	}

	const productCategoriesInCart = cartItems.map((item) => item.categories);
	const flattenedCategoriesInCart = productCategoriesInCart.flat();
	const categoryIdList = flattenedCategoriesInCart.map(
		(category) => category?.id
	);

	// Check product categories
	if (coupon.product_categories.length > 0) {
		// Check if at least one product category is in the applicable categories of the coupon
		if (coupon.product_categories.length > 0) {
			const applicableCategories = coupon.product_categories.some(
				(categoryId) => categoryIdList.includes(categoryId)
			);
			if (!applicableCategories) {
				toast.error(
					"Coupon is not applicable to the products' categories in the cart"
				);
				return false;
			}
		}
	}

	// Check if any product category is in the excluded categories of the coupon
	if (coupon.excluded_product_categories.length > 0) {
		const excludedCategories = coupon.excluded_product_categories.some(
			(categoryId) => categoryIdList.includes(categoryId)
		);

		if (excludedCategories) {
			toast.error(
				"Coupon is not applicable to some products' categories in the cart"
			);
			return false;
		}
	}

	// Check usage count
	if (coupon.usage_limit !== null && coupon.usage_count >= coupon.usage_limit) {
		toast.error("Coupon usage limit has been reached");
		return false;
	}

	// Check coupon usage per user
	if (coupon.usage_limit_per_user !== null) {
		const couponUsedByUsers = coupon.used_by.filter((id) => id === userId);
		const userCount = couponUsedByUsers.length;

		if (userCount === coupon.usage_limit_per_user) {
			toast.error("You have already reached your usage limit.");
			return false;
		}
	}

	// Check for free shipping
	// if (coupon.free_shipping) {
	//     const shippingMethods = cartI.shipping_methods;
	//     if (!shippingMethods.includes("free_shipping")) {
	//         return { success: false, message: "Free shipping is not applicable for the coupon" };
	//     }
	// }
	//   console.log(coupon.email_restrictions.includes(userEmail) )

	// check restrictions
	if (
		coupon.email_restrictions?.length > 0 &&
		(!userEmail || !coupon.email_restrictions.includes(userEmail.toLowerCase()))
	) {
		toast.error("You are unauthorized to use this coupon..");
		return false;
	}

	return true;
};
export const calculatePriceRange = (childProducts: Product[]) => {
	if (childProducts.length === 0) return { min: 0, max: 0 };

	const prices = childProducts.map((product) => parseFloat(product.price));
	const minPrice = Math.min(...prices);
	const maxPrice = Math.max(...prices);

	return { min: minPrice, max: maxPrice };
};

export const ProcessPayment = async (payLoad: any, orderResp: any) => {
	const {
		billing,
		shipping,
		shipping_lines,
		shipping_tax,
		total_tax,
		total,
		discount_total,
		line_items,
		coupon_lines,
	} = orderResp;
	// GETING LINE ITEM DETAILS
	const productDetails: any[] = [];
	line_items.map((data: any, index: number) => {
		productDetails.push({
			item_id: data.id,
			product_id: data.product_id,
			name: data.name,
			price: data.price,
			amount: data.price,
			sku: data.product_id,
			qty: data.quantity,
			image_url: data.image.src,
		});
	});

	const paymentOrder = {
		customer: {
			email: billing.email,
		},
		order: {
			public_id: orderResp.id.toString(),
			quote_id: "123456",
			customer_ip: "127.0.0.1",
			amount: total,
			currency: orderResp.currency,
			data_version: "1661517291",
			return_url: "https://example.com",
			products: productDetails,
			shipping: {
				title: shipping_lines[0].method_title,
				shipping_code: shipping_lines[0].method_id,
				amount: shipping_lines[0].total,
			},
			totals: {
				subtotal: {
					title: "Tax",
					amount: total_tax,
				},
				shipping: {
					title: shipping_lines[0].method_title,
					amount: shipping_lines[0].total,
				},
				tax: {
					title: "Tax",
					amount: total_tax,
				},
				grand_total: {
					title: "Grand Total",
					amount: total,
				},
				discount: {
					title: "Discount",
					amount: discount_total,
				},
			},
		},
		coupons: payLoad.coupon_lines,
		shipping_address: {
			firstname: shipping.first_name,
			lastname: shipping.last_name,
			zip: shipping.postcode,
			country_code: shipping.country,
			state_code: shipping.state,
			city: shipping.city,
			address_1: shipping.address_1,
			address_2: shipping.address_2,
			telephone: shipping.phone,
		},
		billing_address: {
			firstname: billing.first_name,
			lastname: billing.last_name,
			zip: billing.postcode,
			country_code: billing.country,
			state_code: billing.state,
			city: billing.city,
			address_1: billing.address_1,
			address_2: billing.address_2,
			telephone: billing.phone,
		},
		comment: "",
	};
	// console.log(paymentOrder, "Closing");
	let config = {
		method: "post",
		url: `${process.env.GATSBY_PAYMENTFLOW_API_URL}orders/create`,
		headers: {
			Authorization: process.env.GATSBY_PAYMENT_TOKEN,
			"Content-Type": "application/json",
		},
		data: paymentOrder,
	};

	try {
		const paymentStatus = await axios.request(config);
		return paymentStatus.data.data;
	} catch (error) {
		// console.log(error);
	}

	// .then((response) => {
	// 	// console.log(JSON.stringify(response.data));
	// 	const resp = response.data
	// 	console.log(resp.data,"<-----resp")

	// })
	// .catch((error) => {
	// 	console.log(error);
	// });
};

export const JWTChecker = () => {
	// if (isBrowser()) {
	const user = JSON.parse(localStorage.getItem("user") as string);
	// const user: any = {};
	if (!user) {
		return { isLoggedIn: false, email: false };
	}
	const { isExpired } = useJwt(user.token);

	if (isExpired) {
		return { isLoggedIn: false, email: user?.user_email };
	} else {
		return { isLoggedIn: true, email: user?.user_email };
	}
	// } else {
	//   return { isLoggedIn: false, email: null };
	// }
};

export const isBrowser = () => {
	// if (typeof window !== "undefined") return true;
	// else return false;
	return true;
};

export const fetchYotpoReviews = async (productId: any) => {
	const response = await fetch(
		`https://api.yotpo.com/v1/widget/${productId}/reviews`,
		{
			headers: {
				Authorization: `Bearer ${process.env.GATSBY_YOTPO_SECRET}`, // Replace with your Yotpo API key
			},
		}
	);

	if (!response.ok) {
		throw new Error("Failed to fetch reviews");
	}

	const data = await response.json();
	return data;
};

export const getQueryString = (query: string, urlString?: string) => {
	let url;

	if (typeof window !== "undefined") {
		if (urlString) {
			url = new URL(urlString);
		} else if (typeof window !== "undefined") {
			url = new URL(window.location.href);
		} else {
			throw new Error("No URL provided and window is not defined.");
		}

		const queryParams = new URLSearchParams(url.search);
		return queryParams.get(query);
	}
};

export const detectBot = async () => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	const botUserAgents = [
		"googlebot",
		"bingbot",
		"yahoo! slurp",
		"duckduckbot",
		"baiduspider",
		"yandexbot",
		"sogou",
		"exabot",
		"facebot",
		"ia_archiver",
	];

	const isBot = botUserAgents.some((bot) => userAgent.includes(bot));

	if (!isBot) {
		return false;
	} else {
		return true;
	}
};

export function capitalizeFirstLetter(str: string) {
	if (str.length === 0) return str; // Handle empty string
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const logout = (userId: string) => {
	return new Promise(async (resolve, reject) => {
		try {
			await axios.delete(
				`${process.env.GATSBY_SITE_URL}/wp-json/wp/v2/logout-token-delete?user_id=${userId}`,
				{
					headers: {
						Authorization:
							typeof window !== "undefined"
								? JSON.parse(localStorage.getItem("user") as string).token
								: null,
					},
				}
			);

			return resolve("success");
		} catch (error) {
			return reject(error);
		}
	});
};

export function generateUrl(subSlug: any, params: any) {
	const URL = `${process.env.GATSBY_CLIENT_URL}/` || "http://localhost:8000/";
	let BASE;
	if (subSlug.length > 0) {
		const slug = subSlug
			.map((urlSlug: any) => {
				return urlSlug;
			})
			.join("/");

		BASE = `${URL}${slug}`;
	} else {
		BASE = `${URL}`;
	}

	const queryParams = Object.keys(params)
		.map((key) => `${key}=${params[key]}`)
		.join("&");
	return `${BASE}?${queryParams}`;
}

// export const PaymentGatewayList = async (payLoad: any, orderResp: any) => {
//   const OrderToken = nanoid(32);
//   const {
//     billing,
//     shipping,
//     shipping_lines,
//     shipping_tax,
//     total_tax,
//     total,
//     discount_total,
//     line_items,
//     coupon_lines,
//   } = orderResp;
//   // GETING LINE ITEM DETAILS
//   const productDetails: any[] = [];
//   line_items.map((data: any, index: number) => {
//     productDetails.push({
//       item_id: data.id,
//       product_id: data.product_id,
//       name: data.name,
//       price: data.price,
//       amount: data.price,
//       sku: data.product_id,
//       qty: data.quantity,
//       image_url: data.image.src,
//     });
//   });

//   const paymentOrder = {
//     customer: {
//       email: billing.email,
//     },
//     order: {
//       public_id: orderResp.id.toString(),
//       quote_id: "123456",
//       customer_ip: "127.0.0.1",
//       amount: total,
//       token: OrderToken,
//       currency: orderResp.currency,
//       data_version: "1661517291",
//       return_url: "https://example.com",
//       products: productDetails,
//       shipping: {
//         title: shipping_lines[0].method_title,
//         shipping_code: shipping_lines[0].method_id,
//         amount: shipping_lines[0].total,
//       },
//       totals: {
//         subtotal: {
//           title: "Tax",
//           amount: total_tax,
//         },
//         shipping: {
//           title: shipping_lines[0].method_title,
//           amount: shipping_lines[0].total,
//         },
//         tax: {
//           title: "Tax",
//           amount: total_tax,
//         },
//         grand_total: {
//           title: "Grand Total",
//           amount: total,
//         },
//         discount: {
//           title: "Discount",
//           amount: discount_total,
//         },
//       },
//     },
//     coupons: payLoad.coupon_lines,
//     shipping_address: {
//       firstname: shipping.first_name,
//       lastname: shipping.last_name,
//       zip: shipping.postcode,
//       country_code: shipping.country,
//       state_code: shipping.state,
//       city: shipping.city,
//       address_1: shipping.address_1,
//       address_2: shipping.address_2,
//       telephone: shipping.phone,
//     },
//     billing_address: {
//       firstname: billing.first_name,
//       lastname: billing.last_name,
//       zip: billing.postcode,
//       country_code: billing.country,
//       state_code: billing.state,
//       city: billing.city,
//       address_1: billing.address_1,
//       address_2: billing.address_2,
//       telephone: billing.phone,
//     },
//     comment: "",
//   };
//   let config = {
//     method: "post",
//     url: `${process.env.GATSBY_PAYMENTFLOW_API_URL}orders/get-available-payments`,
//     headers: {
//       Authorization: process.env.GATSBY_PAYMENT_TOKEN,
//       "Content-Type": "application/json",
//     },
//     data: paymentOrder,
//   };

//   const paymentStatus = await axios.request(config);
//   // .then((response) => {
//   //    // console.log(JSON.stringify(response.data));
//   //    const resp = response.data
//   //    console.log(resp.data,"<-----resp")

//   // })
//   // .catch((error) => {
//   //    console.log(error);
//   // });
//   return { paymentStatus: paymentStatus.data.data, token: OrderToken };
// };

export const PaymentGatewayList = async (payLoad: any, orderResp: any) => {
	const response: any = await axios.post(
		`${process.env.GATSBY_GATEWAYLIST_API_URL}`,
		{
			payLoad,
			orderResp,
		}
	);
	console.log(payLoad, orderResp);
	return {
		paymentStatus: response.data.paymentStatus,
		token: response.data.token,
	};
};

// export const finalPaymentProcess = async (
//   token: any,
//   paymentDesc: any,
//   orderId: number
// ) => {
//   console.log(token, paymentDesc.form_type, paymentDesc);
//   let payload = {
//     checkout_token: token,
//     payment_code: paymentDesc.payment_code,
//   };

//   let config = {
//     method: "post",
//     maxBodyLength: Infinity,
//     url: `${process.env.GATSBY_PAYMENTFLOW_API_URL}orders/process-payment`,
//     headers: {
//       Authorization: process.env.GATSBY_PAYMENT_TOKEN,
//       "Content-Type": "application/json",
//     },
//     data: payload,
//   };

//   try {
//     const res = await axios.request(config);
//     if (Number(paymentDesc.additional_charge) !== 0) {
//       await wooCommerce.put(`orders/${Number(orderId)}`, {
//         fee_lines: [
//           {
//             name: "Convenience fee",
//             total: Number(paymentDesc.additional_charge),
//           },
//         ],
//       });
//     }

//     return { token, paymentDesc, api_res: res };
//   } catch (error: any) {
//     throw new Error("ERROR DURING PAYMENTPROCESS API CALL", error.message);
//   }
// };

export const finalPaymentProcess = async (
	token: any,
	paymentDesc: any,
	orderId: number
) => {
	let payload = {
		checkout_token: token,
		payment_code: paymentDesc.payment_code,
	};

	try {
		const res: any = await axios.post(
			`${process.env.GATSBY_FINAL_PAY_API_URL}`,
			{
				payload: payload,
			}
		);
		if (Number(paymentDesc.additional_charge) !== 0) {
			await wooCommerce.put(`orders/${Number(orderId)}`, {
				fee_lines: [
					{
						name: "Convenience fee",
						total: Number(paymentDesc.additional_charge),
					},
				],
			});
		}
		return { token, paymentDesc, api_res: res.data.resp };
	} catch (error: any) {
		throw new Error("ERROR DURING PAYMENTPROCESS API CALL", error.message);
	}
};

export const formattedDate = (date: string) => {
	return moment(date).format("MM/DD/YYYY");
};
